import "./styleSheets/landingPage.css";
import ImageCarousel from "./imageGrid";
import React, { useEffect, useState } from "react";

const MehndiPage = () => {
  const [smImages, setSmImages] = useState([]);

  useEffect(() => {
    fetch("/mehndiImages/list.json")
      .then((res) => res.json())
      .then((data) => setSmImages(data.map((file) => `/mehndiImages/${file}`)));
  }, []);

  return (
    <div className="mehndiPage">
      <div className="info-section">
        <div className="landingBox">
          <h1>Mehndi</h1>
          <p style={{ fontSize: "large", padding: "0px 50px" }}>
            Indulge in the beauty of 100% natural, freshly prepared mehndi, free
            from harsh chemicals. Whether it's for your special day or a festive
            celebration, I specialize in both Bridal and guest mehndi art,
            tailored to perfection for any occasion.
            <br />
            <br />
            Bridal mehndi is a cherished journey, meticulously crafted and
            utterly rewarding. Elevate your bridal experience with personalized
            touches – imagine adorable mementos intricately woven into your
            design, from charming portraits of the happy couple to iconic
            landmarks symbolizing your unique love story.
            <br />
            <br />
            Let me transform your mehndi experience into a cherished memory,
            where every stroke tells a tale of love and celebration.
          </p>
          <div class="styled-line"></div>
          <h2>Client/Model Work</h2>
          <ImageCarousel slides={smImages} />
        </div>
      </div>
    </div>
  );
};

export default MehndiPage;
