/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Instagram, Facebook, Mail } from "lucide-react";
import Navigation from "./navigationLinks";
import backgroundIMG from "../images/mehndiBackground.png";
import logo from "../images/logo.png";
import styles from "./Navbar.module.css";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return isMobile;
};

const Navbar = ({ isHomeShrunk, onRevealClick }) => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const [clickDisabled, setClickDisabled] = useState(false);

  useEffect(() => {
    if (pathname !== "/" && isMobile) {
      setClickDisabled(true);
    } else {
      setClickDisabled(false);
    }
  }, [pathname]);

  const handleClick = () => {
    if (pathname === "/" && isHomeShrunk && !clickDisabled) {
      onRevealClick();
    }
  };

  return (
    <motion.div
  className={`${styles.navbarContainer} ${isHomeShrunk ? styles.shrunk : ""}`} // Add shrink class based on state
  animate={{ height: isMobile ? "24vh" : isHomeShrunk ? "24vh" : "100vh" }}
  transition={{ duration: 1.2, ease: [0.65, 0, 0.35, 1] }}
  onClick={handleClick}
>
      <div className={styles.socialLinks}>
        <a
          href="https://www.instagram.com/simmi.mehndi"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.iconWrapper} ${styles.instagram}`}
        >
          <Instagram size={16} strokeWidth={1.5} color="white" />
        </a>
        <a
          href="https://www.facebook.com/simmiravi16/"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.iconWrapper} ${styles.facebook}`}
        >
          <Facebook size={16} strokeWidth={1.5} color="white" />
        </a>
        <a
          href="mailto:simmi.mehndi@gmail.com?subject=Hi, I'd like to get some more information. - (Replace with your Name)"
          className={`${styles.iconWrapper} ${styles.email}`}
        >
          <Mail size={16} strokeWidth={1.5} color="white" />
        </a>
      </div>

      <img
        src={backgroundIMG}
        alt="mehndiImage"
        className={styles.backgroundImage}
      />

<motion.img
  src={logo}
  alt="simmiLogo"
  initial={{ scale: 0.9, y: "-50%", x: "-50%", opacity: 0 }}
  animate={{
    scale: isMobile ? 0.3 : isHomeShrunk ? 0.3 : 0.65, // Adjust logo scale for mobile
    y: isMobile ? "-50%" : "-51.5%",  // Adjust vertical positioning for mobile
    x: "-50%",
    opacity: 1,
    transition: { duration: 1.2, ease: [0.65, 0, 0.35, 1] },
  }}
  style={{
    position: isMobile ? "absolute" : "fixed",  // Use absolute for mobile to ensure positioning
    top: isMobile ? "10%" : "50%",  // Adjust top positioning for mobile and desktop
    left: "50%",
    zIndex: 80,
    margin: isMobile ? "8vh auto" : "0",  // Center the logo and adjust margin for mobile
    maxWidth: isMobile ? "90vh" : "none",  // Limit max-width for mobile
  }}
/>



      <Navigation />
    </motion.div>
  );
};

export default Navbar;
