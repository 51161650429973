import React, { useState, useEffect } from "react";
import "./styleSheets/imageGrid.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ImageCarousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [visibleCount, setVisibleCount] = useState(6); // Start with 8 images

  useEffect(() => {
    if (visibleCount < slides.length) {
      const timer = setTimeout(() => {
        setVisibleCount((count) => count + 10); // Load 4 more at a time
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [visibleCount, slides.length]);

  const openModal = (index) => {
    document.body.classList.add("no-scroll");
    setCurrent(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const navigateSlides = (direction) => {
    setCurrent((current) =>
      direction === "prev"
        ? current === 0
          ? slides.length - 1
          : current - 1
        : current === slides.length - 1
        ? 0
        : current + 1
    );
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <div className="grid">
        {slides.slice(0, visibleCount).map((src, index) => (
          <LazyLoadImage
            key={index}
            src={src}
            alt={`Slide ${index}`}
            effect="blur"
            onClick={() => openModal(index)}
            className="grid-item"
            width="auto"
            loading="lazy"
          />
        ))}
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="slider" onClick={(e) => e.stopPropagation()}>
            <img
              src={slides[current]}
              alt={`Slide ${current}`}
              className="image"
              loading="lazy"
            />
            <div className="navigation-buttons">
              <div
                className="hover-area hover-left"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateSlides("prev");
                }}
              >
                <FaChevronLeft />
              </div>
              <div
                className="hover-area hover-right"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateSlides("next");
                }}
              >
                <FaChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
