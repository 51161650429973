import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LandingPage from "./components/landingPage";
import MehndiPage from "./components/mehndiPage";
import MakeupPage from "./components/makeupPage";
import Footer from "./components/footer";
import { Analytics } from "@vercel/analytics/react";
import Navbar from "./components/Navbar";
import LegalAndFAQ from "./components/Legal";
import ScrollToTop from "./components/ScrollToTop";

import "./App.css";

function AppContent() {
  const location = useLocation();
  const [isShrunk, setIsShrunk] = useState(location.pathname !== "/");

  useEffect(() => {
    if (location.pathname !== "/") {
      setIsShrunk(true);
    }
  }, [location.pathname]);

  const handleRevealClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      setIsShrunk(false);
    }, 500);
  };

  return (
    <>
      <Navbar isHomeShrunk={isShrunk} onRevealClick={handleRevealClick} />
      <Routes>
        <Route path="/" element={<LandingPage setIsShrunk={setIsShrunk} />} />
        <Route path="/mehndi" element={<MehndiPage />} />
        <Route path="/hair&beauty" element={<MakeupPage />} />
        <Route path="/legal" element={<LegalAndFAQ />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Analytics />
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;
