import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./navigationLinks.module.css";

const links = [
  { name: "Home", path: "/" },
  { name: "Mehndi", path: "/mehndi" },
  { name: "Hair & Beauty", path: "/hair&beauty" },
];

const Navigation = () => {
  const location = useLocation();

  const containerRef = useRef();
  const itemRefs = useRef([]);
  const [highlightStyle, setHighlightStyle] = useState({ x: 0, width: 0 });

  useEffect(() => {
    const activeIndex = links.findIndex(
      (link) => link.path === location.pathname
    );
    const activeItem = itemRefs.current[activeIndex];

    if (activeItem && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const itemRect = activeItem.getBoundingClientRect();
      const itemWidth = activeItem.offsetWidth;

      const centerX = itemRect.left + itemWidth / 2 - containerRect.left;
      const newX = centerX - itemWidth / 2;

      setHighlightStyle({ x: newX, width: itemWidth });
    }
  }, [location.pathname]);

  return (
    <div className={styles.pillNavContainer} ref={containerRef}>
      <motion.div
        className={styles.highlightPill}
        animate={highlightStyle}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      />
      {links.map((link, index) => (
        <Link
          key={link.path}
          to={link.path}
          ref={(el) => (itemRefs.current[index] = el)}
          className={styles.navItem}
          onClick={(e) => e.stopPropagation()}
        >
          <span className={styles.navText}>{link.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default Navigation;
