import React from "react";
import "./styleSheets/legal.css";

const LegalAndFAQ = () => {
  return (
    <div className="legal-container">
      <div className="legal-box">
        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-item">
            <h3>How can I book an appointment?</h3>
            <p>
              You can contact me directly through Instagram or Email (Click the
              buttons in the navigation bar)
            </p>
          </div>
          <div className="faq-item">
            <h3>Do you require a deposit?</h3>
            <p>
              Yes, a small deposit is required to secure your booking. This will
              be deducted from your final payment.
            </p>
          </div>
          <div className="faq-item">
            <h3>Where are you based?</h3>
            <p>
              I am based in the UK but can travel for events depending on
              availability and location.
            </p>
          </div>
          <div className="faq-item">
            <h3>What services do you offer?</h3>
            <p>
              I offer Mehndi (Henna) services, Makeup, Hair Styling, and Guest
              Party packages. Full details can be found in the relevant
              sections.
            </p>
          </div>
        </section>

        <section className="legal-section">
          <h2>Conditions of Use & Sale</h2>
          <p>
            By using this website or purchasing services, you agree to the
            following conditions:
          </p>
          <ul>
            <li>All bookings are subject to availability.</li>
            <li>A deposit may be required to secure your booking.</li>
            <li>Any cancellations within 48 hours may forfeit the deposit.</li>
            <li>
              All images and content on this website are the intellectual
              property of Simmi Mehndi.
            </li>
            <li>
              Services are offered at the discretion of the provider and may be
              refused in certain cases.
            </li>
          </ul>

          <h2>Privacy Notice</h2>
          <p>
            Your personal information (name, email, contact details) is
            collected solely for the purpose of communicating with you regarding
            bookings or inquiries. Your data will never be sold or shared with
            third parties. For full privacy information, please contact us
            directly.
          </p>

          <h2>Cookies Notice</h2>
          <p>
            This website uses cookies to enhance your browsing experience.
            Cookies are used only for basic analytics and functionality. By
            continuing to use this website, you consent to our use of cookies.
          </p>
        </section>
      </div>
    </div>
  );
};

export default LegalAndFAQ;
