import React from "react";
import { Link } from "react-router-dom";
import "./styleSheets/footer.css"; // Path to your Footer CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        © 2025 Simmi Mehndi. All rights reserved. - Designed and Created by
        @Timeless.Solutions
      </p>
      <p>
        <Link to="/legal">FAQ & Legal Information</Link>
      </p>
    </footer>
  );
};

export default Footer;
